import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import EmailForm from "components/EmailForm"
import Slider from "components/Slider/OurTechstackSlider"
import TechstackLogos from "components/Slider/TechstackLogos/TechstackLogos"
import Layout from "components/Layout/en"

export default function(props) {
  const header = {
    text: "Frontend Developer",
    description:
      "For the further development of our software solutions and services we are looking for a Frontend Developer in Vienna",
  }
  return (
    <Layout header={header} location={props.location}>
      <Container>
       <Row className="py-4">
         <Col md="12">
           <p>Career</p>
         </Col>
         <Col md="8">
           <h1>Frontend Developer</h1>
           <ul className="list-unstyled lead pt-2">
             <li>
               <strong>Location:</strong> Vienna
             </li>
           </ul>
         </Col>
       </Row>
     </Container>
     <hr id="job_jump" />
     <div className="grey-background py-4">
       <Container>
         <Row>
           <Col md="8">
             <h3 style={{ color: "rgb(6, 162, 199)" }}>About Us</h3>
             <p>
             Work with great minds on the most fulfilling Computer Vision challenges. Since MoonVision’s inception in 2017, we teach machines to automate complex visual tasks. In our areas of focus we do that more precisely, more data efficient and more robust than anyone else. Our clients are leading in their industry and define new standards with MoonVision. For example, pushing the limits in the food industry has a direct impact on thousands of catering guests per day, the success of MoonVision and your experience.
             </p>

             <p>
             To scale our continuously learning systems, we combine established and cutting-edge technologies. You will take over the lead of the frontend part of the online platform at app.moonvison.io. It exists for data, user account and IoT device management as well as for distributed computing tasks.
             </p>

             <h3 style={{ color: "rgb(6, 162, 199)" }}>Skills</h3>
             <p>
             The platform you are building out is at the heart of model creation and deployment processes. It allows clients and us to manage annotated data, trigger long-running computations and store data streams from devices. Over the years, we gathered what makes and breaks such a platform. Every iteration brought us closer to the optimal solution. Nevertheless, there are many challenges left.
             </p>
             <p>Here, we need your expertise and creativity to improve the frontend side of our app.moonvision.io platform. You will work closely with our Backend team as well our CV Engineers to develop new features as well as improve the design of the platform. Therefore, strong affinity for UI/UX is also desired.</p>
             <p>
               Your profile:
             </p>
             <ul>
               <li>Min. 2 years of experience with React and JavaScript (ES6)</li>
               <li>Knowledge of functional programming principles</li>
               <li>You understand the importance of a performant, optimized, and responsive website/WebApp</li>
               <li>
               Experience with creating Designs and Design Systems in Figma(or any other design tool)
               </li>
               <li>Strong interest in usability and user experience</li>
               <li>Familiarity with testing and debugging</li>
               <li>Experience in dealing with version control systems such as Git</li>
               <li>Ability to work both independently and in a team</li>
               <li>Excellent English knowledge, as this is the company working language</li>
             </ul>
             <p>
               It’s nice if you can show practical experience in any of those
               areas:
             </p>
             <ul>
               <li>TypeScript</li>
               <li>Experience in fabric.js or any other JavaScript canvas library
               </li>
               <li>Experience in SASS(or any other CSS preprocessor)</li>
               <li>Experience with CI/CD pipelines and Docker</li>
               <li>Worked in an Agile environment</li>
             </ul>
             <h3 style={{ color: "rgb(6, 162, 199)" }}>Our Tech Stack</h3>
             <ul>
               <li>
                 <strong>DL Frameworks:</strong> Pytorch, Torchvision, scikit
                 learn
               </li>
               <li>
                 <strong>DL Libraries:</strong> CUDA, ONNX, Torchscript,
                 Albumentations, Kornia
               </li>
               <li>
                 <strong>Languages:</strong> Python, C/C++, JavaScript, Rust
                 (in evaluation)
               </li>
               <li>
                 <strong>Frontend:</strong> React{" "}
               </li>
               <li>
                 <strong>Backend:</strong> Django, AioHTTP, OpenAPI Generator,
                 Dask Distributed
               </li>
               <li>
                 <strong>Data:</strong> PostgreSQL, Redis, MlFlow, Prefect
               </li>
               <li>
                 <strong>Traditional Computer Vision:</strong> OpenCV, FFmpeg
               </li>
               <li>
                 <strong>Packaging:</strong> Docker, Kubernetes, Yarn
               </li>
             </ul>
             <p>
               To learn more about the inner workings, visit{" "}
               <a href="https://medium.com/moonvision">our tech blog.</a>
             </p>
           </Col>
         </Row>
       </Container>
     </div>
     <div className="grey-background py-4">
       <Slider logos={TechstackLogos} />
     </div>
     <div className="grey-background py-4">
       <Container>
         <Row>
           <Col md="8">
             <h3 style={{ color: "rgb(6, 162, 199)" }}>Team</h3>
             <p>
               We are a team of 6 engineers and 6 people for BD and operations.
               You will work closely with the technical product owner, head of
               computer vision solutions, internal enterprise and app
               developers and partners that integrate with our API. We work
               hard to ensure our inclusive and diverse office is a workplace
               where many nationalities, ideologies and backgrounds thrive to
               cooperate with one another. It’s highly encouraged to
               participate in hackathons, visit conferences, organize meetups,
               and contribute to{" "}
               <a href="https://github.com/moonVision">open source.</a>
             </p>
             <p>
               In many aspects, we set the pace for industry-wide and new AI
               solutions. Hence, creative yet organized teamwork is a must.
               Multilingual and multicultural background appreciated! We strive
               to maintain a professional spirit by:
             </p>
             <ul>
               <li>Working in an agile environment</li>
               <li>Encouraging ownership of core ideas and deliverables</li>
               <li>
                 Being a mentor and being open to mentorship from colleagues
               </li>
             </ul>

             <h3 style={{ color: "rgb(6, 162, 199)" }}>Work Environment</h3>
             <p>
               Our headquarter is in Vienna, Austria, the most liveable city of
               the world. We have social security, no crime, old and new
               cultures, good cuisine, affordable housing and vibrant people
               from all over. Our office is modern and well equipped with
               amenities for other than work activities too. Of course, we have
               an office dog. Olli provides plenty of opportunities to show him
               your affection. Also, we are neighbors with one of the coolest
               web agencies out there. As we like to say: there is no one
               boring week at MoonVision. And if you don’t feel like coming on
               a particular day, there is a Home Office option of at least one
               day per week.
             </p>

             <h3 style={{ color: "rgb(6, 162, 199)" }}>Recruiting Process</h3>
             <p>
               First, we’ll check your potential fit based on skills and
               experience described in your application. If there is, we’ll
               send you a list of take-home exercises where you choose one that
               suits you. If the solution convinces our tech lead, we’ll do an
               interview and take it from there. At any point, feel free to ask
               your point of contact.
             </p>
             <h3 style={{ color: "rgb(6, 162, 199)" }}>
               International applicants
             </h3>
             <p>
               We onboarded people from many places around the world. Hence,
               supporting you with moving, visas and additional bureaucracy is
               possible.
             </p>
             <h3 style={{ color: "rgb(6, 162, 199)" }}>Benefits</h3>
             <ul>
               <li>Home Office Days</li>
               <li>Sport Subscription Service</li>
               <li>Bring your own Pet Policy</li>
               <li>Fruit Basket</li>
               <li>Modern Office Amenities</li>
               <li>Leisure Equipment like gaming consoles</li>
               <li>Company Hackathons</li>
               <li>Flat Hierarchy</li>
               <li>Education Budget</li>
               <li>Choose your own working Laptop</li>
             </ul>
             <h3 style={{ color: "rgb(6, 162, 199)" }}>Compensation</h3>
             <p>
               <strong>Salary:</strong> Between € 40.000 - € 60.000 a year,
               depending on skill level for 40 hours per week (pro-rata for
               part-time).
             </p>
             <p>
               <strong>Hours:</strong> Between 32-40h a week. We value a
               healthy work life balance. You will have 5 weeks paid vacation a
               year, full health insurance, pension plan and paid maternity
               leave.
             </p>
           </Col>
         </Row>
       </Container>
       <Container>
         <Row>
           <Col md="8" className="mt-5">
             <EmailForm deVersion={true} position="Frontend Developer"/>
           </Col>
         </Row>
       </Container>
     </div>
   </Layout>
 )
}
